'use strict';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

const main = async () => {
  // import('./import/css');
  import('./import/bootstrap_');

  if (window.location.pathname === '/' ||
    window.location.pathname === '/index.php') {
    import('./import/intersectionObserver');
    import('./import/bootstrap-carousel');
  }

  if (
    window.location.pathname.includes('fotoalbum') ||
    window.location.pathname.includes('verslagen')
  ) {
    import('./import/intersectionObserver');
    import('./import/bootstrap-tooltip');
    import('./import/lightgallery');
  }

  if (window.location.pathname.includes('contact')) {
    import('./import/vue-uploadform.js');
  }
};

main();

//
// EOF
//
